import $ from 'jquery';
import Common from './common';
import mainJson from '../json/main.json';

const _main = () => {
  $.getJSON(mainJson, (json) => {
    const { title } = json;
    $('#main-logo-text').html(title[Common.language]);
  });
};

const _mainMobile = () => {
  $.getJSON(mainJson, (json) => {
    const { title } = json;
    $('#main-logo-text').html(title[Common.language]);
  });
};

export { _main, _mainMobile };

import $ from 'jquery';
import headerJson from '../json/header.json';
import Common from './common';

const scrollManage = () => {
  const scroll = $(window).scrollTop();
  if (scroll > 0) {
    $('#header').addClass('active-scroll');
  }
  let lastScroll = 0;
  $(window).scroll(() => {
    const currentScroll = $(window).scrollTop();
    if (currentScroll > 0) {
      $('#header').addClass('active-scroll');

      if (currentScroll < lastScroll) {
        $('#header').removeClass('disappear-scroll');
      } else if (currentScroll > 200) {
        $('#header').addClass('disappear-scroll');
      }
    } else {
      $('#header').removeClass('active-scroll');
    }
    lastScroll = currentScroll;
  });
};

const _header = () => {
  $.getJSON(headerJson, (json) => {
    const data = json[Common.language];
    const { divName } = json;
    for (let i = 0; i < data.length; i++) {
      let content = (i >= 0 ? (data[i]) : '');
      let subMenu = '';
      if (typeof (content) !== 'string') {
        // eslint-disable-next-line prefer-destructuring
        content = Object.keys(content)[0];
        for (let j = 0; j < data[i][content].length; j++) {
          subMenu += `<span>${data[i][content][j]}</span>`;
        }
      }
      const dataDivName = divName[json.en[i]] !== undefined ? divName[json.en[i]] : undefined;

      if (dataDivName) {
        $('#header-container ul#header-menu').append(`<li ${dataDivName ? `data-div="${dataDivName}"` : ''}>${content}</li>`);
      } else {
        $('#header-container ul#header-menu').append(`
        <li id="header-expand-li">
        
        <div id="header-expand-container">
          ${subMenu}
        </div>
          ${content}
        </li>`);
      }
    }

    $('#header-menu li').click((e) => {
      const domId = $(e.currentTarget).data('div');
      if (domId !== undefined) {
        $('html,body').animate({ scrollTop: $(`#${domId}`).offset().top }, 500);
      } else {
        $(e.currentTarget).click(undefined);
      }
    });

    $('#header-language li').click((e) => {
      const language = $(e.currentTarget).data('param');
      window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}?lang=${language}`;
      // console.log(, language);
    });

    $('#header-expand-li').mouseenter((e) => {
      $(e.currentTarget).addClass('header-expand-li-active');
      $('#header-expand-container').addClass('header-expand-show');
    });

    $('#header-expand-li').mouseleave((e) => {
      $(e.currentTarget).removeClass('header-expand-li-active');
      $('#header-expand-container').removeClass('header-expand-show');
    });
    $('#header-expand-container span').click((e) => {
      const index = $(e.currentTarget).index();
      switch (index) {
        case 2:
          window.location.href = './contact';
          break;
        default:
          $('html,body').animate({ scrollTop: $('#notice_press').offset().top }, 500);
          break;
      }
    });
  });

  scrollManage();
};

const _headerMobile = () => {
  $('#header-mobile-expand-icon').click(() => {
    if ($('#navigation').hasClass('show')) {
      $('#navigation').removeClass('show');
      $('#header-mobile-expand-icon').removeClass('close');
    } else {
      $('#navigation').addClass('show');
      $('#header-mobile-expand-icon').addClass('close');
    }
  });
};

export { _header, _headerMobile };

import $ from 'jquery';
import partnerJson from '../json/partner.json';
import Common from './common';

const _partner = () => {
  $.getJSON(partnerJson, (json) => {
    Common.settingText($('#partner'), json);
    const { data } = json;
    for (let i = 0; i < data.length; i++) {
      const eachData = data[i];

      $('#partner-container').append(`
      <div class="partner-card" style="background:${eachData.backgroundColor};" data-aos="fade-up">
        <img src="${eachData.imagePath}">
        <div class="partner-information-container">
          <a href="${eachData.link}" target="_blank"><div class="partner-information"></div></a>
          <p class="partner-information-text">${eachData.name}</p>
        </div>
      </div>
      `);
    }
    $('.partner-information-container').mouseenter((e) => {
      $(e.currentTarget).addClass('partner-information-container-show');
    });

    $('.partner-information-container').mouseleave((e) => {
      $(e.currentTarget).removeClass('partner-information-container-show');
    });
  });
};

const _partnerMobile = () => {
  $.getJSON(partnerJson, (json) => {
    Common.settingTextMobile($('#partner-mobile'), json);
    const { data } = json;
    for (let i = 0; i < data.length; i++) {
      const eachData = data[i];

      $('#partner-container').append(`
      <div class="partner-card" style="background:${eachData.backgroundColor};">
        <img src="${eachData.imagePath}">
        <div class="partner-information-container">
          <a href="${eachData.link}" target="_blank"><div class="partner-information"></div></a>
          <p class="partner-information-text">${eachData.name}</p>
        </div>
      </div>
      `);
    }
  });
};

export { _partner, _partnerMobile };

import $ from 'jquery';
import Common from './common';
import contactJson from '../json/contact.json';

const _contact = () => {
  $.getJSON(contactJson, (json) => {
    $('#contact-button-map-text').text(json.map[Common.language]);
    $('#contact-button-contact-text').text(json.contact[Common.language]);
    $('#contact-information-address-text').html(json.address[Common.language]);
  });

  $('#contact-button-container p').mouseenter((e) => {
    $(e.currentTarget).addClass('underline');
  });

  $('#contact-button-container p').mouseleave((e) => {
    $(e.currentTarget).removeClass('underline');
  });
};

const _contactMobile = () => {
  $.getJSON(contactJson, (json) => {
    $('#contact-button-map-text').text(json.map[Common.language]);
    $('#contact-button-contact-text').text(json.contact[Common.language]);
    $('#contact-information-address-text').html(json.address[Common.language]);
  });
};

export { _contact, _contactMobile };

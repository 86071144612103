import $ from 'jquery';
import gameJson from '../json/game.json';
import Common from './common';

const _game = () => {
  $.getJSON(gameJson, (json) => {
    Common.settingText($('#game'), json);
    const { data } = json;
    for (let i = 0; i < data.length; i++) {
      const eachData = data[i];
      const textObject = eachData[Common.language];
      const {
        backgroundGradient, textColor, imagePath, linkColor, link,
      } = eachData;

      const backgroundPercent = eachData.backgroundPercent || 100;

      const gradientStyle = `"background-image: linear-gradient(to bottom,${backgroundGradient[0]},${backgroundGradient[1]});"`;
      const textStyle = `"color: ${textColor}"`;
      const imageStyle = `"width:${backgroundPercent}%"`;
      const linkImagePath = `./image/link${linkColor === 'white' ? 2 : 1}.png`;

      $('#game .card-container').append(
        `<div class="card ${
          i % 3 === 1 ? "card-center" : ""
        }" data-aos="fade-up" data-aos-delay="${(i % 3) * 100}">
          <div class="card-content" style=${gradientStyle}>
            <img class="card-image" style=${imageStyle} src=${imagePath}>
            <p class="card-title" style=${textStyle}>${textObject.title}</p>
            <p class="card-description" style=${textStyle}>${textObject.description}</p>
            <a href="${link}" target="_blank"><img class="card-link"  src=${linkImagePath}></img></a>
          </div>
        </div>`,
      );
    }
  });

  let clicked = false;
  let clickX;
  let clickLeft;
  const updateScrollPos = (e) => {
    let moveLeft = clickLeft - (clickX - e.pageX);
    if (moveLeft > 0) {
      moveLeft = 0;
    } else if ($('#game .card-container').width() + moveLeft < $('body').width()) {
      moveLeft = $('body').width() - $('#game .card-container').width();
    }
    $('#game .card-container').css('marginLeft', `${moveLeft}px`);
  };
  $('#game').on({
    mousemove(e) {
      if (clicked) {
        updateScrollPos(e);
      }
    },
    mousedown(e) {
      clicked = true;
      clickX = e.pageX;
      clickLeft = parseInt($('#game .card-container').css('marginLeft'), 10);
    },
    mouseup() {
      clicked = false;
      $('html').css('cursor', 'auto');
    },
  });

  $('body').mouseout(() => {
    clicked = false;
  });
};

const _gameMobile = () => {
  $.getJSON(gameJson, (json) => {
    Common.settingTextMobile($('#game-mobile'), json);
    const { data } = json;
    for (let i = 0; i < data.length; i++) {
      const eachData = data[i];
      const textObject = eachData[Common.language];
      const {
        backgroundGradient, textColor, imagePath, linkColor, link,
      } = eachData;

      const backgroundPercent = eachData.backgroundPercent || 100;

      const gradientStyle = `"background-image: linear-gradient(to bottom,${backgroundGradient[0]},${backgroundGradient[1]});"`;
      const textStyle = `"color: ${textColor}"`;
      const imageStyle = `"width:${backgroundPercent}%"`;
      const linkImagePath = `./image/link${linkColor === 'white' ? 2 : 1}.png`;

      $('#game-mobile .card-container').append(
        `<div class="card">
          <div class="card-content" style=${gradientStyle}>
            <img class="card-image" style=${imageStyle} src=${imagePath}>
            <p class="card-title" style=${textStyle}>${textObject.title}</p>
            <a href="${link}" target="_blank"><img class="card-link"  src=${linkImagePath}></img></a>
          </div>
        </div>`,
      );
    }
  });
};

export { _game, _gameMobile };

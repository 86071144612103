import $ from 'jquery';
import Common from './common';
import teamJson from '../json/member.json';

const _member = () => {
  $.getJSON(teamJson, (json) => {
    Common.settingText($('#member'), json);
    const { data } = json;


    const CoFounderClass = Common.language === 'ko' ? 'co-founder-ko' : 'co-founder-en';
    for (let i = 0; i < data.length; i++) {
      const eachData = data[i];
      const isCoFounder = eachData.founder !== undefined;
      // const imagePath = `./image/member/${(eachData.name.en.split(' ')[1] + (eachData.name.en.split(' ')[2] ? eachData.name.en.split(' ')[2] : '')).toLowerCase()}.jpg`;
      const imagePath = `./image/member/${eachData.name.en.split(' ')[1].replace('-', '').toLowerCase()}.jpg`;

      $('#member-container').append(`
        <div class="member-people-container" data-aos="fade-up">
          <div class="member-people-picture ${isCoFounder ? CoFounderClass : ''}" style="background-image:url('${imagePath}');"></div>
          <p class="member-people-name">${eachData.name[Common.language]}</p>
          <p class="member-people-position">${eachData.position[Common.language]}</p><br>
          <p class="member-people-role">${eachData.role[Common.language]}</p>
        </div>
      `);
    }
    $('.member-people-container').mouseenter((e) => {
      $(e.currentTarget).find('.member-people-position').addClass('member-people-position-effect');
      $(e.currentTarget).find('.member-people-role').addClass('member-people-role-effect');
    });

    $('.member-people-container').mouseleave((e) => {
      $(e.currentTarget).find('.member-people-position').removeClass('member-people-position-effect');
      $(e.currentTarget).find('.member-people-role').removeClass('member-people-role-effect');
    });
  });
};

const _memberMobile = () => {
  $.getJSON(teamJson, (json) => {
    Common.settingTextMobile($('#member-mobile'), json);
    const { data } = json;


    const CoFounderClass = Common.language === 'ko' ? 'co-founder-ko' : 'co-founder-en';
    for (let i = 0; i < data.length; i++) {
      const eachData = data[i];
      const isCoFounder = eachData.founder !== undefined;
      // const imagePath = `./image/member/${(eachData.name.en.split(' ')[1] + (eachData.name.en.split(' ')[2] ? eachData.name.en.split(' ')[2] : '')).toLowerCase()}.jpg`;
      const imagePath = `./image/member/${eachData.name.en.split(' ')[1].replace('-', '').toLowerCase()}.jpg`;

      $('#member-container').append(`
        <div class="member-people-container">
          <div class="member-people-picture ${isCoFounder ? CoFounderClass : ''}" style="background-image:url('${imagePath}');"></div>
          <p class="member-people-name">${eachData.name[Common.language]}</p>
          <p class="member-people-position">${eachData.position[Common.language]}</p>
          <p class="member-people-role">${eachData.role[Common.language]}</p>
        </div>
      `);
    }
  });
};

export { _member, _memberMobile };

import $ from 'jquery';

const _navigation = () => {
  $('#navigation-background').click(() => {
    $('#navigation').removeClass('show');
    $('#header-mobile-expand-icon').removeClass('close');
  });
};

export default _navigation;

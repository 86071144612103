import $ from 'jquery';
import Common from './common';
import noticeJson from '../json/notice.json';
import newsJson from '../json/news.json';


const viewAll = {
  ko: '전체보기',
  en: 'more',
};

// eslint-disable-next-line no-extend-native,func-names
Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1; // getMonth() is zero-based
  const dd = this.getDate();

  return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('-');
};

const setting = (parent, json) => {
  $(parent).find('.np-title').text(json.title[Common.language]);
  $(parent).find('.np-viewall').text(viewAll[Common.language]);
  const { data } = json;
  for (let i = data.length - 1; i >= 0; i--) {
    if (data.length - i > 5) {
      break;
    }
    const eachData = data[i];
    $(parent).find('table').append(`
    <tr>
      <td width="80%"><a href="notice/?no=${i + 1}">${eachData.title}</a></td>
      <td width="20%">${eachData.date}</td>
    </tr>
    `);
  }
};
const _noticeNews = () => {
  $.getJSON(noticeJson, (json) => {
    setting('#notice', json);
  });
  $.getJSON(newsJson, (json) => {
    $('#press').find('.np-title').text(json.title[Common.language]);
    $('#press').find('.np-viewall').text(viewAll[Common.language]);

    $.ajax({
      type: 'get',
      url: 'http://game1.zip-lab.co.kr/ext/get-blog-rss.php',
      dataType: 'json',
      success: (rssData) => {
        const data = rssData.rss[0].channel[0].item;
        let count = 0;
        for (let i = 0; i < data.length; i++) {
          const eachData = data[i];

          if (eachData.tag[0]._.split(',')[0] === 'news') {
            const {
              title, pubDate, category, link,
            } = eachData;
            const date = new Date(pubDate[0]._);
            $('#press').find('table').append(`
              <tr>
                <td width="80%"><a href="${link[0]._}" target="_blank">[${category[0]._}] - <span>${title[0]._}</span></a></td>
                <td width="20%">${date.yyyymmdd()}</td>
              </tr>
            `);
            count++;
          }
          if (count > 4) {
            break;
          }
        }
      },
      error: (xhr, status) => {
        console.log(xhr, status);
      },
    });
  });
};

const _noticeMobile = () => {
  $.getJSON(noticeJson, (json) => {
    setting('#notice-mobile', json);
  });
};

const _newsMobile = () => {
  $.getJSON(newsJson, (json) => {
    $('#news-mobile').find('.np-title').text(json.title[Common.language]);
    $('#news-mobile').find('.np-viewall').text(viewAll[Common.language]);

    $.ajax({
      type: 'get',
      url: 'http://game1.zip-lab.co.kr/ext/get-blog-rss.php',
      dataType: 'json',
      success: (rssData) => {
        const data = rssData.rss[0].channel[0].item;
        let count = 0;
        for (let i = 0; i < data.length; i++) {
          const eachData = data[i];
          if (eachData.tag[0]._.split(',')[0] === 'news') {
            const {
              title, pubDate, category, link,
            } = eachData;
            const date = new Date(pubDate[0]._);
            $('#news-mobile').find('table').append(`
              <tr>
                <td width="80%"><a href="${link[0]._}" target="_blank">[${category[0]._}] - <span>${title[0]._}</span></a></td>
                <td width="20%">${date.yyyymmdd()}</td>
              </tr>
            `);
            count++;
          }
          if (count > 4) {
            break;
          }
        }
      },
      error: (xhr, status) => {
        console.log(xhr, status);
      },
    });
  });
};

export { _noticeNews, _noticeMobile, _newsMobile };

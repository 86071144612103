import $ from 'jquery';
import Common from './common';
import footerJson from '../json/footer.json';

const _footer = () => {
  $.getJSON(footerJson, (json) => {
    if (Common.language === undefined) {
      Common.setLanguage('ko');
    }
    $('#footer-name').text(json.name[Common.language]);
    $('#footer-address').text(json.address[Common.language]);
    $('#footer-ceo').text(json.CEO[Common.language]);
    $('#footer-privacy').html("<a href='./privacy'>"+json.privacy[Common.language]+"</a>");
  });
};

export default _footer;

import $ from 'jquery';
import Swiper from 'swiper';
import Common from './common';
import doJson from '../json/do.json';

const setActiveMenu = (element, index) => {
  $(element).removeClass('do-menu-inactive');
  $(element).addClass('do-menu-active');

  $('.do-bullet-active-line').css('width', `${index * 50}%`);
  $('.do-bullet').removeClass('do-bullet-active');
  for (let i = 0; i < index; i++) {
    $($('.do-bullet')[i]).addClass('do-bullet-active');
  }
};

const setInactiveMenu = (element) => {
  $(element).removeClass('do-menu-active');
  $(element).addClass('do-menu-inactive');
};

const _do = () => {
  $.getJSON(doJson, (json) => {
    for (let i = 0; i < 3; i++) {
      const navigation = $($('#do .do-menu-item')[i]).find('p');
      const title = $($('#do .text-container')[i]).find('.text-title');
      const description = $($('#do .text-container')[i]).find('.text-description');

      $(navigation).text(json.data[i].title[Common.language]);
      $(title).text(json.data[i].title[Common.language]);
      $(description).text(json.data[i].description[Common.language]);
    }
  });
  let activeMenu = $('.do-menu-active')[0];
  const mySwiper = new Swiper('.swiper-container', {
    on: {
      slideChange: () => {
        $('.do-content-item.aos-init').removeClass('aos-animate');
        setInactiveMenu($('.do-menu-item')[mySwiper.previousIndex]);
        setActiveMenu($('.do-menu-item')[mySwiper.activeIndex], mySwiper.activeIndex);
      },
      slideChangeTransitionStart: () => {
      },
      slideChangeTransitionEnd: () => {
        $('.swiper-slide-active > div > .do-content-item').addClass('aos-animate');
      },
    },
  });

  setTimeout(() => {
    $('.do-content-item.aos-init').removeClass('aos-animate');
    // $('.swiper-slide-active > div > .do-content-item').addClass('aos-animate');
  }, 100);

  $('.do-menu-item').click((e) => {
    setInactiveMenu(activeMenu);
    activeMenu = e.currentTarget;
    const index = $(e.currentTarget).data('index');

    setActiveMenu(e.currentTarget, index);
    mySwiper.slideTo(index);
  });
};

const _doMobile = () => {
  $.getJSON(doJson, (json) => {
    for (let i = 0; i < 3; i++) {
      const navigation = $($('#do-mobile .do-menu-item')[i]).find('p');
      const title = $($('#do-mobile .text-container')[i]).find('.text-title');
      const description = $($('#do-mobile .text-container')[i]).find('.text-description');

      $(navigation).text(json.data[i].title[Common.language]);
      $(title).text(json.data[i].title[Common.language]);
      $(description).text(json.data[i].description[Common.language]);
    }
  });
};

export { _do, _doMobile };

import $ from 'jquery';

export default class Common {
  static setLanguage(language) {
    this.language = language;
    if (language === 'en') {
      $('body').addClass('en-font');
    }
  }

  static setPlatform(platform) {
    this.platform = platform;
  }

  static settingText(dom, json) {
    $(dom).prepend(`
      <div class="sub-header">
        <p class="title" data-aos="fade-up">${json.title[this.language]}</p>
        <div class="divider" id="divider" data-aos="fade-up" data-aos-delay="100"></div>
        <p class="description" data-aos="fade-up" data-aos-delay="200">${json.description[this.language]}</p>
      </div>
    `);
  }

  static settingTextMobile(dom, json) {
    $(dom).prepend(`
      <div class="sub-header">
        <p class="title">${json.title[this.language]}</p>
        <div class="divider" id="divider"></div>
        <p class="description">${json.description[this.language]}</p>
      </div>
    `);
  }
}

import $ from 'jquery';
import Common from './common';
import historyJson from '../json/history.json';

const _history = () => {
  $.getJSON(historyJson, (json) => {
    Common.settingText($('#history'), json);
    const { data } = json;
    for (let i = 0; i < data.length; i++) {
      const eachData = data[i];
      $('#history-container').append(`
      <div class="history-year-container" id="history-${eachData.year}">
        <div class="history-year" data-aos="fade-right">
          <p>${eachData.year}</p>
        </div>
        <div class="history-month-content-container"></div>
      </div>`);
      for (let j = 0; j < eachData.history.length; j++) {
        const history = eachData.history[j];
        $(`#history-${eachData.year} .history-month-content-container`).append(`
        <div class="history-month-content-line" data-aos="fade-right">
          <div class="history-month-container">
            <p>${parseInt(history.month, 10) < 10 ? `0${history.month}` : history.month}</p>
          </div>
          <div class="history-content-container">
            <p>${history.content[Common.language]}</p>
          </div>
        </div>
        `);
      }
    }
  });
};

const _historyMobile = () => {
  $.getJSON(historyJson, (json) => {
    Common.settingTextMobile($('#history-mobile'), json);
    const { data } = json;
    for (let i = 0; i < data.length; i++) {
      const eachData = data[i];
      $('#history-container').append(`
      <div class="history-year-container" id="history-${eachData.year}">
        <div class="history-year">
          <p>${eachData.year}</p>
        </div>
        <div class="history-month-content-container"></div>
      </div>`);
      for (let j = 0; j < eachData.history.length; j++) {
        const history = eachData.history[j];
        $(`#history-${eachData.year} .history-month-content-container`).append(`
        <div class="history-month-content-line">
          <div class="history-month-container">
            <p>${parseInt(history.month, 10) < 10 ? `0${history.month}` : history.month}</p>
          </div>
          <div class="history-content-container">
            <p>${history.content[Common.language]}</p>
          </div>
        </div>
        `);
      }
    }
  });
};

export { _history, _historyMobile };
